import { LoginFormComponent } from '../../../shared/layout/login-form/login-form.component';
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "../auth.service";
import { LoginBusinessComponent } from 'src/app/shared/layout/login-business/login-business.component';
import { AuthShareholdersService } from '../auth-shareholders.service';

@Injectable({
  providedIn: 'root'
})
export class AuthShareHoldersGuard  {
  constructor(readonly auth: AuthShareholdersService, private router: Router) {}


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // Obtén el rol del usuario desde tu servicio de autenticación
    if (this.auth.isAuthenticated()) {
        return true;
      } else { 
        this.router.navigate(['/junta-de-accionistas/login']) 
        return false;
      }
  }

}