import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthShareholdersService } from 'src/app/core/services/auth-shareholders.service';

@Component({
  selector: 'app-shareholders-header',
  templateUrl: './shareholders-header.component.html',
  styleUrl: './shareholders-header.component.scss'
})
export class ShareholdersHeaderComponent {

  constructor(private auth: AuthShareholdersService, private router: Router){

  }
  logout(){
    this.auth.signOut();
    this.router.navigate(['/junta-de-accionistas/login'])
  }
}
