import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { tap, catchError, of, throwError } from 'rxjs';
import { AuthShareholdersService } from 'src/app/core/services/auth-shareholders.service';

@Component({
  selector: 'app-shareholders-login',
  templateUrl: './shareholders-login.component.html',
  styleUrl: './shareholders-login.component.scss'
})
export class ShareholdersLoginComponent {
  public loginForm!: FormGroup;
  public submitted: boolean = false;
  public passError: boolean = false
  

  constructor(private formBuilder: FormBuilder, private auth: AuthShareholdersService, private router: Router){
    this.loginForm = this.formBuilder.group({
      username:["",[Validators.required]],
      password:["",[Validators.required, Validators.minLength(3)]],
      
    })

  }

  onSubmit() {
    const username = this.loginForm.get('username')?.value;
    const password = this.loginForm.get('password')?.value;
    this.auth.signIn(username, password).pipe(
      tap((res) => {
        this.router.navigate(['/junta-de-accionistas']);
      }),
      catchError((error) => {
        this.passError = true;
        return of(error);
      })
    ).subscribe();
  }
}
