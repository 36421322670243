import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { catchError, of, tap } from 'rxjs';
import { ShareHoldersService } from 'src/app/core/services/shareholders/share-holders.service';

@Component({
  selector: 'app-shareholders-password',
/*   standalone: true,
  imports: [], */
  templateUrl: './shareholders-password.component.html',
  styleUrl: './shareholders-password.component.scss'
})
export class ShareholdersPasswordComponent {

  public dataForm!: FormGroup;
  public alertMessage: string = "";
  public alertStatus: string = "";
  public showAlert: boolean = false;
  
  constructor(private formBuilder: FormBuilder, private shService: ShareHoldersService){
    this.dataForm = this.formBuilder.group({
      password:['', [Validators.required, Validators.minLength(6), Validators.maxLength(15)]],
      rePassword:['', [Validators.required, Validators.minLength(6), Validators.maxLength(15)]]
    })

    this.dataForm.valueChanges.subscribe(res => {
      const pin = this.dataForm.get('password')?.value
      const repeatPin = this.dataForm.get('rePassword')?.value
      if ( pin !== repeatPin) {
        this.dataForm.get('rePassword')?.setErrors({ rePasswordError: 'Las contraseñas no coinciden' })
      }else {
        this.dataForm.get('rePassword')?.setErrors(null);
    }
    })
  }

  submit(){
    const body = {
      password : this.dataForm.get('password')?.value,
      rePassword : this.dataForm.get('rePassword')?.value,
    }

    this.shService.changePasswordSh(body).pipe(
      tap((res)=> {
        this.alertMessage = 'Contraseña cambiada correctamente';
        this.alertStatus = 'ok'
      }),
      catchError((error) => {
         this.alertMessage = 'Hubo un error al cambiar la contraseña';
        this.alertStatus = 'ko'
        return of (null)
      })
    ).subscribe(()=> {
      this.showAlert = true;
    })
    this.dataForm.reset()
  }

}
