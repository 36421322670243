import { HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { downloadFile } from 'src/app/core/extensions/download-files';
import { DataShareHolder, ShareholdersData } from 'src/app/core/models/shareholders/shareholders-data';
import { ShareHoldersService } from 'src/app/core/services/shareholders/share-holders.service';
import { ConfirmationModalComponent } from 'src/app/shared/components/confirmation-modal/confirmation-modal.component';


@Component({
  selector: 'app-shareholders-data',
  templateUrl: './shareholders-data.component.html',
  styleUrl: './shareholders-data.component.scss'
})
export class ShareholdersDataComponent implements OnInit{
  public shareholdersData!: DataShareHolder[];
  public tablePaginatorInfo!: ShareholdersData;
  public paginationParams: HttpParams = new HttpParams();
  public offset: number = 10;
  public isAdmin!: boolean;
  public restartUploadButton: boolean = true;
  public alert!: boolean;
  date: any
  title: any
  icon: any

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  displayedColumns: string[] = [
    'title',
    'createdAt',
    'icon',
  ]

  dataSource = new MatTableDataSource<DataShareHolder>()

  constructor (private shareholdersDataService: ShareHoldersService, private dialog: MatDialog){}

  ngOnInit(): void {
    this.paginationParams = this.paginationParams.set('page', 1).set('offset', this.offset)
    this.getMinuteList();
    this.setUserType();
  }

  //llamada para obtener la lista de la tabla
  getMinuteList(){
    this.shareholdersDataService.getMinutesList(this.paginationParams).subscribe(res =>{
      this.shareholdersData = res.data!;
      this.tablePaginatorInfo = res;
      this.dataSource = new MatTableDataSource<DataShareHolder>(this.shareholdersData);
    })
  }
  // set del rol desde el token
  setUserType(){
    const token = sessionStorage.getItem('tokenShareholder')!;
    const [header, payload, signature] = token.split('.');
    const decodedPayload = JSON.parse(atob(payload));
    const roles = decodedPayload.realm_access.roles
    this.isAdmin = roles.some((rol: string) => rol === 'partner_admin')
    if (this.isAdmin) {
      this.displayedColumns.push('second_icon');
    }
  }

  //evento para obtener la paginación
  onPageChange(event: any){
    this.paginationParams = this.paginationParams.set('page', event.pageIndex + 1)
    this.getMinuteList()
  }
  //funcion para confirmar borrado de documento
  public openModal(id: string ) {
    this.dialog.open(ConfirmationModalComponent, {
      disableClose: true,
      data: {
        modalMessage: '¿Desea eliminar este documento?',
        modalOption: 'Eliminar',
        action: () => {
          this.shareholdersDataService.deleteMinutes(id).subscribe(
            res =>{
              this.getMinuteList();
            }
          )
        }
      }
    });
    
  }

  //descarga de documento

  downloadDocument(id: string, name: string){
    this.shareholdersDataService.downloadMinutes(id).subscribe(
      res => {
        downloadFile(res, name)
      } 
    )
  }
  //output para ver cuando se sube correctamente el archivo
  recieveUploadedOk(event: boolean){
    if(event){
      this.restartUploadButton = false;
      this.alert = true;
      setTimeout(() => {
        this.restartUploadButton = true;
        this.getMinuteList();
      }, 100);

    }
  }
  //funcion que recibe por output el cierre del alert cuando vas a subir otro archivo.
  recieveCloseAlert(event: boolean){
    if(event){
     this.alert = false;
    }
  }
}
