<div class="header_container">
    <div class="upper-container">
        <div class="logo_container">
            <img src="../../../../assets/images/logos/denarius.svg" alt="" routerLink="/">
        </div>
        <button class="mat-icon-shareholders" mat-icon-button (click)="logout()">
            <mat-icon>power_settings_new</mat-icon>
        </button>
    </div>
</div>
