
import { LoginService } from './login/login.service';
import { Injectable, OnDestroy } from "@angular/core";
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, interval, Observable, of, Subscription, tap } from "rxjs";
import { Segment } from '../models/plans/plans';

@Injectable()
export class AuthShareholdersService implements OnDestroy {
  tokenShareholders$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private logout: Subscription = new Subscription;

  constructor(public jwtHelper: JwtHelperService, private loginService: LoginService) {
 
  }

  public isAuthenticated(): boolean {
    const token = sessionStorage.getItem('tokenShareholder');
    return !this.jwtHelper.isTokenExpired(token!);
  }

  public signIn(username: string, password: string, segment?: Segment): Observable<any> {
    const req = {
      username: username, 
      password: password,
    
    };
    
      return this.loginService.loginShareholders(req)
        .pipe(
          tap(res => {
            sessionStorage.setItem('tokenShareholder', res.accessToken!)
            this.setTokenSessionStorage();
            /* this.setUserRole(Segment.INDIVIDUAL); */
            
          }) 
          
        )

    }
 
  getTokenSessionStorage(): Observable<boolean> { 
    return this.tokenShareholders$.asObservable();
  }

  setTokenSessionStorage() {
    this.tokenShareholders$.next(this.checkToken());
  }
  checkToken() { 
    if(sessionStorage.getItem('tokenShareholder')) {
      return true
    } else {
      return false
    }
  }

  public signOut() {
    sessionStorage.clear();
    this.setTokenSessionStorage();
  }

  public token() {
    return sessionStorage.getItem('tokenShareholder')
  }

  ngOnDestroy() { 
    this.logout.unsubscribe();
  }
}