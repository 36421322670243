import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { ShareholdersRoutingModule } from './shareholders-routing.module';
import { ShareholdersLoginComponent } from './shareholders-login/shareholders-login.component';
import { ShareholdersDataComponent } from './shareholders-data/shareholders-data.component';
import { ShareholdersHeaderComponent } from './shareholders-header/shareholders-header.component';
import { ShareholdersPasswordComponent } from './shareholders-password/shareholders-password.component';



@NgModule({
  declarations: [
    ShareholdersLoginComponent,
    ShareholdersDataComponent,
    ShareholdersHeaderComponent,
    ShareholdersPasswordComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ShareholdersRoutingModule
  ],
  exports: [
    ShareholdersHeaderComponent,
    ShareholdersPasswordComponent
  ]
})
export class ShareholdersModule { }
