import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ShareholdersLoginComponent } from './shareholders-login/shareholders-login.component';
import { ShareholdersDataComponent } from './shareholders-data/shareholders-data.component';
import { AuthShareHoldersGuard } from 'src/app/core/services/guards/auth-shareHolders.guard';
import { ShareholdersPasswordComponent } from './shareholders-password/shareholders-password.component';

const routes: Routes = [
  
  {
    path: 'login', component: ShareholdersLoginComponent,

  },
  {
    path: '', component: ShareholdersDataComponent,
    canActivate: [AuthShareHoldersGuard]
  },
  {
    path: 'password', component: ShareholdersPasswordComponent,
    canActivate: [AuthShareHoldersGuard]
  },
  
  ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ShareholdersRoutingModule { }