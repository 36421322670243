<div class="container">
  <div class="banner">
    <h1>Junta de <span>Accionistas</span></h1>
  </div>
  <div *ngIf="showAlert">
    <app-alerts
      [alertMessage]="alertMessage"
      [alertStatus]="alertStatus"
    ></app-alerts>
  </div>
  <div class="body-password">
    <div class="password-container">
      <form [formGroup]="dataForm" class="form-container">
        <div class="info-title">
          <h2>Cambiar contraseña</h2>
          <div class="line"></div>
        </div>
        <div class="password-form">
          <!--               <mat-error>La contraseña no es correcta</mat-error> -->
          <p>Contraseña nueva</p>
          <input
            
            formControlName="password"
            type="password"
            placeholder="Introduce tu nueva contraseña"
          />
          <mat-error *ngIf="dataForm.get('password')?.invalid && dataForm.get('password')?.touched">La contraseña debe tener entre 6 y 15 caracteres</mat-error>

          <p>Reescriba la contraseña</p>
          <input
            
            formControlName="rePassword"
            type="password"
            placeholder="Reescribe tu nueva contraseña"
          />
          <mat-error *ngIf="(dataForm.get('rePassword')?.invalid || dataForm.get('rePassword')?.hasError('rePasswordError') )&& dataForm.get('rePassword')?.touched">Las contraseñas no coinciden</mat-error>
          <div class="buttons">
            <button
              class="gold-stroked-button"
              routerLink="/junta-de-accionistas"
            >
              Volver
            </button>
            <button
              [disabled]="!dataForm.valid"
              class="gold-color-button"
              type="submit"
              (click)="submit()"
            >
              Cambiar
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
