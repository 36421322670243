<div class="login_page">
  <div class="login_container">
    <img src="../../../../assets/images/logos/icono_denarius.png" alt="" />
    <form class="login_form" [formGroup]="loginForm">
      <div class="title-container">
        <h2>Junta de</h2>
        <h1>accionistas</h1>
      </div>
      <div class="inputs">
        <input
          formControlName="username"
          #username
          placeholder="Introduce tu email"
        />
      </div>
      <div class="inputs">
        <input
          placeholder="Introduce tu contraseña"
          formControlName="password"
          #password
          type="password"
        />
      </div>
    </form>
    <p *ngIf="passError" class="errorPass">
      Nombre de usuario o contraseña no válidos
    </p>
    <div class="line"></div>
    <button
      [disabled]="!loginForm.valid"
      class="gold-color-button"
      (click)="onSubmit()"
      aria-label="register_btn"
      [disabled]="!loginForm.valid"
    >
      Acceder
    </button>
    <!-- <a href="">¿Has olvidado tu contraseña?</a> -->
  </div>
  <div class="gold-stroked-button" routerLink="/para-empresas">
    Volver a inicio
  </div>
</div>
